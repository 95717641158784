import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { State } from "vuex-class";
import debounce from "lodash.debounce";
import {
  cloneStructured,
  formatDate,
  formatMoney,
  getFullName,
  getSymbolCurrency,
} from "@helpers";
import {
  cardModule,
  profileModule,
  userModule,
  walletModule,
  bankModule,
  teamModule,
  warningModule,
} from "@store/namespaces";
import { CardActions, CardGetters } from "@store/modules/card/types";
import { ProfileGetters, ProfileActions } from "@store/modules/profile/types";
import { WalletActions, WalletGetters } from "@store/modules/wallet/types";
import { Card, CardStatus, CardType } from "@/types/card";
import { Currency } from "@/types/currency";
import { HtmlElementId } from "@/types/element";
import { UserWebPreferencesKey } from "@/types/user";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { accessibleBatchCardActions } from "@/lib/card";
import { formatCardExpire } from "@/lib/creaditCard";

import {
  TopBarFilter,
  TableHeaderItems,
  TableHeaderValue,
  TopBarFilterItems,
} from "@/types/components/filter-table-top-bar";
import { cardStatuses } from "@config/card";
import { BankGetters, BankActions } from "@store/modules/bank/types";

import { Role } from "@/types/role";
import { TeamGetters, TeamActions } from "@store/modules/team/types";
import { DictionaryBank } from "@/types/dictionary";
import { WarningType } from "@/types/warning";
import { RootState } from "@store/types";
import { WarningActions } from "@store/modules/warning/types";
import { StepAlias } from "@/types/productTour";
import mockCards from "@/mockData/cards";

interface Filter {
  owners: {
    teamlead: string[];
    mediabuyer: string[];
    other: string[];
  };
}

type TopBarTableFilter = TopBarFilter<"status", CardStatus> &
  TopBarFilter<"group", number | null> &
  TopBarFilter<"currency", Currency> &
  TopBarFilter<"bank", number>;

type CardBank = Record<
  string,
  { text: string; value: number; imageSrc?: string }
>;

type SelectedCard = Pick<Card, "id" | "status">;

interface CardItem extends Card {
  bank?: DictionaryBank;
  overdraftAmountInfo: string;
}

@Component({
  components: {
    BatchIssueCardsProgress: () =>
      import("@/features/BatchIssueCardsProgress/BatchIssueCardsProgress.vue"),
    BatchUpdateCardsProgress: () =>
      import(
        "@/features/BatchUpdateCardsProgress/BatchUpdateCardsProgress.vue"
      ),
    WelcomeScreen: () => import("@/components/WelcomeScreen/WelcomeScreen.vue"),
  },
})
export default class CardsMixin extends Vue {
  @State("isReady") private readonly isReadyApp!: RootState["isReady"];

  @warningModule.Action("fetchFeedWarning")
  private readonly fetchFeedWarningAction!: WarningActions["fetchFeedWarning"];
  @walletModule.Action("fetchWallets")
  private readonly fetchWalletsAction!: WalletActions["fetchWallets"];
  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @profileModule.Getter
  protected readonly profileFreeCardsTotalPromocode!: ProfileGetters["profileFreeCardsTotalPromocode"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("accessToLimitCardsPage")
  private readonly accessToLimitCardsPageGetter!: ProfileGetters["accessToLimitCardsPage"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @profileModule.Action("fetchAvailablePromocodes")
  protected readonly fetchAvailablePromocodesAction!: ProfileActions["fetchAvailablePromocodes"];

  @cardModule.Action("fetchCards")
  private readonly fetchCardsAction!: CardActions["fetchCards"];
  @cardModule.Action("updateCard")
  private readonly updateCardAction!: CardActions["updateCard"];
  @cardModule.Action("closeCard")
  private readonly closeCardAction!: CardActions["closeCard"];
  @cardModule.Action("freezeCard")
  private readonly freezeCardAction!: CardActions["freezeCard"];
  @cardModule.Action("unfreezeCard")
  private readonly unfreezeCardAction!: CardActions["unfreezeCard"];
  @cardModule.Getter("postpaidCards")
  private readonly postpaidCardsGetter!: CardGetters["postpaidCards"];
  @cardModule.Getter("prepaidCards")
  private readonly prepaidCardsGetter!: CardGetters["prepaidCards"];
  @cardModule.Getter("loadingCards")
  private readonly loadingCardsGetter!: CardGetters["loadingCards"];
  @cardModule.Getter("loadedCards")
  protected readonly loadedCardsGetter!: CardGetters["loadedCards"];
  @cardModule.Getter("noIssuedCards")
  private readonly noIssuedCardsGetter!: CardGetters["noIssuedCards"];
  @cardModule.Getter("cardGroups")
  private readonly cardGroupsGetter!: CardGetters["cardGroups"];

  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];
  @bankModule.Getter("allBanks")
  private readonly allBanksGetter!: BankGetters["allBanks"];
  @bankModule.Action("fetchAllBanks")
  private readonly fetchAllBanksAction!: BankActions["fetchAllBanks"];
  @teamModule.Getter("teamleadMembers")
  private readonly teamleadMembersGetter!: TeamGetters["teamleadMembers"];
  @teamModule.Getter("mediabuyerMembers")
  private readonly mediabuyerMembersGetter!: TeamGetters["mediabuyerMembers"];
  @teamModule.Getter("members")
  private readonly membersGetter!: TeamGetters["members"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];
  @walletModule.Getter("loadedWalletsCurrencies")
  private readonly loadedWalletsCurrenciesGetter!: WalletGetters["loadedWalletsCurrencies"];

  private selectedCards: Record<number, SelectedCard> = {};
  private unfreezingCardIds: number[] = [];
  private forceUpdateUserWebPreferencesKey = Date.now();
  private batchIssueCardsProgressReloadKey = Date.now();
  private batchUpdateCardsProgressReloadKey = Date.now();
  private forceEmptyLoadingKey = Date.now();
  private cards: {
    search: {
      value: string;
    };
    forceUpdateKey: number;
    showEmptyLoading: boolean;
    loading: boolean;
    itemsPerPage: number;
    filter: Filter;
  } = {
    forceUpdateKey: Date.now(),
    itemsPerPage: 15,
    showEmptyLoading: true,
    loading: false,
    search: {
      value: "",
    },
    filter: {
      owners: {
        teamlead: [],
        mediabuyer: [],
        other: [],
      },
    },
  };
  private topBarTableHeaderValue: Readonly<TableHeaderValue> = [];
  private topBarTableFilter: TopBarTableFilter = {
    group: [],
    bank: [],
    currency: [],
    status: cardStatuses.map((value) =>
      Object.freeze({
        selected: [
          CardStatus.ACTIVE,
          CardStatus.FREEZE,
          CardStatus.SUSPEND,
        ].includes(value),
        value,
      })
    ),
  };

  private get selectedCardsCount() {
    return Object.keys(this.selectedCards).length;
  }

  private get countCardsWithBatchActions() {
    return this.items.filter((card) => card.accessBatchCardActions).length;
  }

  private get cardGroups() {
    if (this.isPrepaidCardType) {
      return this.cardGroupsGetter.filter((item) =>
        item.cardTypes.includes(CardType.PREPAID)
      );
    }

    return this.cardGroupsGetter.filter((item) =>
      item.cardTypes.includes(CardType.POSTPAID)
    );
  }

  private get htmlElementId() {
    return {
      welcomeScreen: HtmlElementId.welcomeScreen,
      balanceCardsPage: HtmlElementId.balanceCardsPage,
      limitCardsPage: HtmlElementId.limitCardsPage,
      cardsFiltersContainerId: HtmlElementId.cardsFiltersContainerId,
      cardsDownloadFilesContainerId:
        HtmlElementId.cardsDownloadFilesContainerId,
      issueCardButton: HtmlElementId.issueCardButton,
      cardsTable: this.isPrepaidCardType
        ? HtmlElementId.balanceCardsTable
        : HtmlElementId.limitCardsTable,
    };
  }

  private get pageHtmlElementId() {
    return !this.isPrepaidCardType
      ? this.htmlElementId.limitCardsPage
      : this.htmlElementId.balanceCardsPage;
  }

  private get includeWarningTypes() {
    return !this.isPrepaidCardType && !this.queryActiveCardIds.length
      ? [WarningType.CARD_SUSPENDED]
      : [];
  }

  private get queryActiveCardIds() {
    const { cardIds } = this.$route.query;

    return typeof cardIds === "string"
      ? cardIds.split(",").map(Number).filter(Number.isInteger)
      : [];
  }

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  protected get isShowWelcomeScreen(): boolean {
    if (this.isShowedMainIntro) {
      return (
        this.$productTour.activeIntro.step.alias === StepAlias.WELCOME_SCREEN
      );
    }

    return (
      this.noIssuedCardsGetter && !!this.profileFreeCardsTotalPromocode.bonus
    );
  }

  private get isShowIssueCard() {
    return this.$route.query.showedIssueCard === "1";
  }

  private set isShowIssueCard(showed) {
    const { name, params, query } = this.$route;

    if (!name) return;

    const newQuery = { ...query };

    if (showed) {
      newQuery.showedIssueCard = "1";
    } else {
      delete newQuery.showedIssueCard;
    }

    const newRouteFullPath = this.$router.resolve({
      name,
      params,
      query: newQuery,
    }).href;

    if (newRouteFullPath === this.$route.fullPath) return;

    if (showed) {
      this.$router.push(newRouteFullPath);
    } else {
      this.$router.replace(newRouteFullPath);
    }
  }

  private get groupMultiselectValue() {
    return this.topBarTableFilter.group.reduce<Array<number | null>>(
      (acc, { selected, value }) => {
        if (!selected) return acc;

        acc.push(value);
        return acc;
      },
      []
    );
  }

  private set groupMultiselectValue(groupIds) {
    this.topBarTableFilter = {
      ...this.topBarTableFilter,
      group: this.cardGroups.map(({ value }) =>
        Object.freeze({
          value,
          selected: groupIds.includes(value),
        })
      ),
    };
  }

  private get topBarSuccessButton() {
    if (this.queryActiveCardIds.length) {
      return {
        title: this.$vuetify.lang.t("$vuetify.dashboard.show_all_cards"),
        icon: "$creditCard",
        id: this.htmlElementId.issueCardButton,
        to: {
          name: !this.isPrepaidCardType
            ? "cards-with-limit"
            : "cards-with-balance",
        },
      };
    }

    return {
      title: this.$vuetify.lang.t("$vuetify.dashboard.issue_card"),
      icon: "$creditCardB",
      id: this.htmlElementId.issueCardButton,
      listeners: {
        click: this.showIssueCard,
      },
    };
  }

  private get activeCardType() {
    switch (this.$route.name) {
      case "cards-with-limit":
        return CardType.POSTPAID;

      case "cards-with-balance":
        return CardType.PREPAID;

      default:
        return null;
    }
  }

  private get isShowCardGroupMultiselect() {
    if (this.isShowTeamleadSelect && this.isShowMediabuyerSelect) {
      return false;
    }

    return (
      this.$vuetify.breakpoint.mobile || this.$vuetify.breakpoint.width > 1400
    );
  }

  private get isShowTeamMemberSelect() {
    return (
      this.userHasRoleGetter(Role.ROLE_TEAMLEAD) &&
      !this.isShowTeamleadSelect &&
      !this.isShowMediabuyerSelect &&
      !this.queryActiveCardIds.length
    );
  }

  private get isShowTeamleadSelect() {
    return (
      this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]) &&
      !this.queryActiveCardIds.length
    );
  }

  private get isShowMediabuyerSelect() {
    return (
      this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]) &&
      !this.queryActiveCardIds.length
    );
  }

  private get cardsLoading() {
    return (
      this.loadingCardsGetter ||
      this.cards.showEmptyLoading ||
      this.cards.loading
    );
  }

  private get cardsList() {
    const isPrepaidCardType = this.activeCardType === CardType.PREPAID;

    const cardsList = isPrepaidCardType
      ? this.prepaidCardsGetter
      : this.postpaidCardsGetter;
    const banksList = this.allBanksGetter;

    return cardsList.map<Readonly<CardItem>>((item) => {
      const overdraftAmountInfo =
        item.overdraftAmount > 0
          ? this.$vuetify.lang.t(
              isPrepaidCardType
                ? "$vuetify.warnings.overdraft_admedia_prepaid_card"
                : "$vuetify.warnings.overdraft_admedia_postpaid_card",
              formatMoney({
                value: item.overdraftAmount,
                currency: item.currency,
                spaceCurrency: false,
              })
            )
          : "";

      return Object.freeze({
        ...item,
        bank: banksList.find(({ id }) => id === item.bankId),
        overdraftAmountInfo,
      });
    });
  }

  private get cardBanks() {
    return Object.values(
      this.cardsList.reduce<CardBank>((acc, { bank }) => {
        if (!bank || acc[bank.id]) return acc;

        acc[bank.id] = {
          text: bank.name,
          value: bank.id,
          imageSrc: bank.country
            ? require(`@/assets/img/country/${bank.country}.svg`)
            : undefined,
        };

        return acc;
      }, {})
    );
  }

  private get filterFields() {
    if (this.queryActiveCardIds.length) {
      return [];
    }

    return [
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        type: "checkbox",
        name: "status",
        items: cardStatuses.map((value) => ({
          text: this.$vuetify.lang.t(
            `$vuetify.dashboard.cards.status.${value.toLowerCase()}`
          ),
          value,
        })),
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        type: "checkbox",
        name: "currency",
        items: this.walletsCurrenciesGetter.map((value) => ({
          text: getSymbolCurrency(value),
          value,
        })),
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.bank"),
        type: "checkbox",
        name: "bank",
        items: this.cardBanks,
        enabled: this.cardBanks.length > 0,
      },
      {
        title: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.group_name"
        ),
        type: "checkbox",
        name: "group",
        items: this.cardGroups,
        enabled: this.cardGroups.length > 0 && !this.isShowCardGroupMultiselect,
      },
    ].filter(({ enabled = true }) => enabled);
  }

  private get headers() {
    let cardNumberWidth = this.$vuetify.breakpoint.width < 2000 ? 260 : "20%";

    if (this.$vuetify.breakpoint.lgAndDown) {
      cardNumberWidth = 228;
    }

    const headers: (DataTableHeader & { enabled?: boolean })[] = [
      {
        text: "",
        value: "select",
        sortable: false,
        enabled: !this.$vuetify.breakpoint.mobile,
        width: this.$vuetify.breakpoint.lgAndDown ? 0 : 53,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.card_number"
        ),
        value: "cardNumber",
        sortable: false,
        width: cardNumberWidth,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.date"),
        value: "cardExpiry",
        sortable: true,
        width: this.$vuetify.breakpoint.xlOnly ? 74 : 49,
      },
      {
        text: "CVC",
        value: "cardCvv",
        align: "start",
        sortable: false,
        width: this.$vuetify.breakpoint.xlOnly ? 90 : 70,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.issued_at"),
        value: "issued_at",
        align: "center",
        sortable: true,
        width: this.$vuetify.breakpoint.xlOnly ? 92 : 80,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        value: "status",
        align: "center",
        sortable: false,
        width: this.$vuetify.breakpoint.xlOnly ? 113 : 105,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.group_name"
        ),
        value: "cardGroupName",
        align: "center",
        sortable: false,
        width: 130,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.owner"),
        value: "owner",
        align: "center",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.spend"),
        value: "spendAmount",
        align: "center",
        enabled: this.isPrepaidCardType,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.balance"),
        value: "balanceAmount",
        align: "center",
        enabled: this.isPrepaidCardType,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.daily_spend"
        ),
        value: "dailyLimit",
        align: "center",
        sortable: false,
        enabled: !this.isPrepaidCardType,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.total_spend"
        ),
        value: "totalLimit",
        align: "center",
        sortable: false,
        enabled: !this.isPrepaidCardType,
      },
      {
        text: "",
        value: "overdraftAmount",
        enabled: !this.$vuetify.breakpoint.mobile,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        value: "currency",
        align: "center",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        align: "end",
        sortable: false,
        enabled: !this.userIsGhostGetter,
      },
    ];

    return headers.filter(({ enabled = true }) => enabled);
  }

  private get excludeTopBarHeaders() {
    if (this.$vuetify.breakpoint.mobile) {
      return [
        "cardExpiry",
        "cardCvv",
        "cardNumber",
        "overdraftAmount",
        "action",
        "select",
      ];
    }

    return ["cardNumber", "overdraftAmount", "action", "select"];
  }

  private get topBarTableHeaderItems() {
    const excludeTopBarHeaders = this.excludeTopBarHeaders;

    const items: TableHeaderItems = this.headers.filter(
      ({ value }) => !excludeTopBarHeaders.includes(value)
    );

    return items;
  }

  private get showedHeaders() {
    if (this.isShowedMainIntro) {
      return this.headers.filter(
        ({ value }) => !["issued_at", "cardGroupName", "owner"].includes(value)
      );
    }

    const excludeTopBarHeaders = this.excludeTopBarHeaders;
    const topBarTableHeaderValue = this.topBarTableHeaderValue;
    const isMobile = this.$vuetify.breakpoint.mobile;

    return this.headers.filter(({ value }) => {
      if (isMobile && ["cardNumber", "cardExpiry", "cardCvv"].includes(value)) {
        return false;
      }

      return (
        excludeTopBarHeaders.includes(value) ||
        topBarTableHeaderValue.some(
          ({ columnName, selected }) => columnName === value && selected
        )
      );
    });
  }

  private get filterOwners() {
    if (this.isShowTeamMemberSelect) {
      return this.cards.filter.owners.other;
    }

    return [
      ...this.cards.filter.owners.teamlead,
      ...this.cards.filter.owners.mediabuyer,
    ];
  }

  private get isPrepaidCardType() {
    return this.activeCardType === CardType.PREPAID;
  }

  protected get items(): Card[] {
    if (
      this.isShowedMainIntro &&
      [
        StepAlias.MOCK_CARDS_ACTIONS,
        StepAlias.MOCK_CARDS_LIST,
        StepAlias.MOCK_CARDS_SET_LIMIT,
      ].includes(this.$productTour.activeIntro.step.alias as StepAlias)
    ) {
      const isPrepaidCardType = this.isPrepaidCardType;

      return mockCards.filter(({ prepaid }) =>
        isPrepaidCardType ? prepaid : !prepaid
      );
    }

    if (this.cards.showEmptyLoading) {
      return [];
    }

    const queryActiveCardIds = this.queryActiveCardIds;

    let items: CardItem[] = [];

    if (queryActiveCardIds.length) {
      items = this.cardsList.filter(({ id }) =>
        queryActiveCardIds.includes(id)
      );
    } else {
      const {
        status: filterStatuses,
        bank: filterBanks,
        currency: filterCurrency,
        group: filterGroups,
      } = this.topBarTableFilter;

      const filterOwners = this.filterOwners;

      items = this.cardsList.filter(
        ({
          status,
          currency,
          cardGroup,
          bankId,
          owner: { email: ownerEmail },
        }) =>
          filterOwners.some((value) => value === ownerEmail) &&
          filterStatuses.some(
            ({ value, selected }) => value === status && selected
          ) &&
          filterBanks.some(
            ({ value, selected }) => value === bankId && selected
          ) &&
          (!cardGroup ||
            filterGroups.some(
              ({ value, selected }) => value === cardGroup.id && selected
            )) &&
          filterCurrency.some(
            ({ value, selected }) => value === currency && selected
          )
      );
    }

    const searchStr = this.cards.search.value.trim().toLowerCase();

    if (searchStr.length) {
      items = items.filter((item) => {
        const owner = getFullName({
          firstName: item.owner.firstName,
          lastName: item.owner.lastName,
          fallback: item.owner.email,
        }).toLowerCase();

        const note = (
          item.note ||
          this.$vuetify.lang.t("$vuetify.dashboard.card_n", item.id)
        ).toLowerCase();

        return (
          item.cardNumber.includes(searchStr.replace(/ /g, "")) ||
          item.cardGroupName?.includes(searchStr) ||
          formatDate(item.issued_at).includes(searchStr.replace(/ /g, "")) ||
          note.includes(searchStr) ||
          item.cardGroupName?.toLowerCase().includes(searchStr) ||
          owner.includes(searchStr)
        );
      });
    }

    return items;
  }

  private getDownloadedData() {
    const headers = this.headers.filter(
      (header) => !!header.value && !!header.text
    );

    headers.splice(1, 0, {
      text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.comment"),
      sortable: false,
      value: "note",
    });

    return {
      items: this.cardsList.map((item) => {
        const note = (
          item.note ||
          this.$vuetify.lang.t("$vuetify.dashboard.card_n", item.id)
        ).toLowerCase();

        const status =
          item.status === CardStatus.ACTIVE
            ? this.$vuetify.lang.t("$vuetify.dashboard.cards.status.active")
            : this.$vuetify.lang.t("$vuetify.dashboard.cards.status.closed");
        const dailyLimit = `${formatMoney({
          value: item.dailySpendAmount,
          currency: item.currency,
          showSymbol: false,
        })} / ${
          item.dailyLimitAmount
            ? formatMoney({
                value: item.dailyLimitAmount,
                currency: item.currency,
                showSymbol: false,
              })
            : "∞"
        }`;

        const totalLimit = `${formatMoney({
          value: item.spendAmount,
          currency: item.currency,
          showSymbol: false,
        })} / ${
          item.limitAmount
            ? formatMoney({
                value: item.limitAmount,
                currency: item.currency,
                showSymbol: false,
              })
            : "∞"
        }`;

        return {
          ...item,
          dailyLimit,
          totalLimit,
          status,
          note,
          cardExpiry: formatCardExpire(
            item.cardExpiryMonth,
            item.cardExpiryYear
          ),
          currency: getSymbolCurrency(item.currency),
          issued_at: formatDate(item.issued_at),
          owner: getFullName({
            firstName: item.owner.firstName,
            lastName: item.owner.lastName,
            fallback: item.owner.email,
          }),
        };
      }),
      headers: headers.reduce<Record<string, string>>(
        (acc, { value, text }) => {
          acc[value] = text;

          return acc;
        },
        {}
      ),
    };
  }

  private onChangeTopBarTableFilter(val: TopBarTableFilter) {
    this.topBarTableFilter = val;
  }

  private onChangeTableHeaderValue(value: TableHeaderValue) {
    this.topBarTableHeaderValue = value;
  }

  private async onCloseCard(id: number) {
    this.cards.loading = true;
    try {
      await this.closeCardAction(id);
      this.cards.loading = false;
      this.fetchCards();
      this.fetchWalletsAction();
    } catch {
      this.cards.loading = false;
    }
  }

  private async onFreezeCard(id: number) {
    this.cards.loading = true;

    try {
      await this.freezeCardAction(id);
      this.cards.loading = false;
      this.fetchCards();
      this.fetchWalletsAction();
    } catch {
      this.cards.loading = false;
    }
  }

  private async onUnfreezeCard(id: number) {
    this.cards.loading = true;
    this.unfreezingCardIds.push(id);

    try {
      await this.unfreezeCardAction(id);
      this.cards.loading = false;
      this.fetchCards();
      this.fetchWalletsAction();
      this.fetchFeedWarningAction();
    } catch {
      this.cards.loading = false;
    } finally {
      this.unfreezingCardIds = this.unfreezingCardIds.filter(
        (unfreezingCardId) => unfreezingCardId !== id
      );
    }
  }

  private async onChangeNoteCard(note: string, { id }: Card) {
    this.cards.loading = true;

    try {
      await this.updateCardAction({ id, note });
      this.cards.loading = false;
      this.fetchCards();
    } catch {
      this.cards.loading = false;
    }
  }

  private async onChangeGroupCard(cardGroupId: number, { id }: Card) {
    this.cards.loading = true;

    try {
      const { cardGroup } = await this.updateCardAction({ id, cardGroupId });

      if (cardGroup) {
        this.topBarTableFilter.group = this.topBarTableFilter.group.filter(
          ({ value }) => value !== cardGroup.id
        );

        this.topBarTableFilter.group.push(
          Object.freeze({
            value: cardGroup.id,
            selected: true,
          })
        );
      }

      this.cards.loading = false;
      this.fetchCards();
    } catch {
      this.cards.loading = false;
    }
  }

  private showIssueCard() {
    this.isShowIssueCard = true;
  }

  private hideIssueCard() {
    this.isShowIssueCard = false;
  }

  private selectCard(selected: boolean, { id, status }: SelectedCard) {
    if (selected) {
      this.$set(this.selectedCards, id, { id, status });
    } else {
      this.$delete(this.selectedCards, id);
    }
  }

  private onSelectCard(selected: boolean, item: Card) {
    this.selectCard(selected, item);
  }

  private onSelectAllCard(selected: boolean) {
    if (!selected) {
      this.selectedCards = {};
    } else {
      this.items.forEach(({ id, status }) => {
        if (!accessibleBatchCardActions({ status })) return;

        this.selectCard(true, { id, status });
      });
    }
  }

  private onBatchIssueCards() {
    this.batchIssueCardsProgressReloadKey++;
    this.fetchCards();
  }

  private onBatchUpdateCards() {
    this.batchUpdateCardsProgressReloadKey++;
    this.selectedCards = {};
    this.fetchCards();
  }

  private forceEmptyLoading() {
    this.forceEmptyLoadingKey++;
  }

  private reloadCards() {
    this.cards.forceUpdateKey++;
  }

  private mapTopBarTableFilterGroup() {
    this.topBarTableFilter.group = this.cardGroups.map(({ value }) =>
      Object.freeze({
        selected:
          this.topBarTableFilter.group.find((group) => group.value === value)
            ?.selected ?? true,
        value,
      })
    );
  }

  private mapTopBarTableFilterBank() {
    this.topBarTableFilter.bank = this.cardBanks.map(({ value }) =>
      Object.freeze({
        selected:
          this.topBarTableFilter.bank.find((bank) => bank.value === value)
            ?.selected ?? true,
        value,
      })
    );
  }

  private async fetchCards() {
    if (this.cards.loading) return;

    const loadedCards = this.loadedCardsGetter;

    this.cards.loading = true;

    try {
      await this.fetchCardsAction({
        forceUpdate: true,
      });

      if (loadedCards) {
        this.mapTopBarTableFilterGroup();
        this.mapTopBarTableFilterBank();
      }
    } finally {
      this.cards.loading = false;
    }
  }

  private async initWebPreferences() {
    const userWebPreferencesKey = this.isPrepaidCardType
      ? UserWebPreferencesKey.BALANCE_CARDS
      : UserWebPreferencesKey.LIMIT_CARDS;

    await this.fetchUserWebPreferencesAction({
      key: userWebPreferencesKey,
    });

    const { value } = this.userWebPreferencesGetter({
      key: userWebPreferencesKey,
    });

    const userWebPreferences = value as Partial<{
      topBarTableHeaderValue: TableHeaderValue;
      topBarTableFilter: Partial<TopBarTableFilter>;
      itemsPerPage: number;
      filter: Partial<Filter>;
    }> | null;

    const teamleadOwnersFromStorage =
      userWebPreferences?.filter?.owners?.teamlead?.filter(
        (teamleadEmail) =>
          this.profileEmailGetter === teamleadEmail ||
          this.teamleadMembersGetter.some(
            ({ email }) => email === teamleadEmail
          )
      );

    this.cards.filter.owners.teamlead = teamleadOwnersFromStorage?.length
      ? teamleadOwnersFromStorage
      : this.teamleadMembersGetter
          .map(({ email }) => email)
          .concat([this.profileEmailGetter]);

    const mediabuyerOwnersFromStorage =
      userWebPreferences?.filter?.owners?.mediabuyer?.filter(
        (mediabuyerEmail) =>
          this.mediabuyerMembersGetter.some(
            ({ email }) => email === mediabuyerEmail
          )
      );

    this.cards.filter.owners.mediabuyer = mediabuyerOwnersFromStorage?.length
      ? mediabuyerOwnersFromStorage
      : this.mediabuyerMembersGetter.map(({ email }) => email);

    const otherOwnersFromStorage =
      userWebPreferences?.filter?.owners?.other?.filter(
        (otherEmail) =>
          otherEmail === this.profileEmailGetter ||
          this.membersGetter.some(({ email }) => email === otherEmail)
      );

    this.cards.filter.owners.other = otherOwnersFromStorage?.length
      ? otherOwnersFromStorage
      : [this.profileEmailGetter];

    this.cards.itemsPerPage =
      userWebPreferences?.itemsPerPage ?? this.cards.itemsPerPage;

    this.topBarTableHeaderValue = this.topBarTableHeaderValue.map(
      ({ columnName, text, selected }) =>
        Object.freeze({
          columnName,
          text,
          selected:
            userWebPreferences?.topBarTableHeaderValue?.find(
              (item) => item.columnName === columnName
            )?.selected ?? selected,
        })
    );

    this.topBarTableFilter = Object.entries(
      cloneStructured(this.topBarTableFilter)
    ).reduce<TopBarTableFilter>((acc, [fieldName_, fieldValue_]) => {
      const fieldName = fieldName_ as keyof TopBarTableFilter;
      const fieldValue = fieldValue_ as TopBarFilterItems<string>;

      const fieldValueFromStorage = (
        userWebPreferences?.topBarTableFilter
          ? userWebPreferences.topBarTableFilter[fieldName]
          : undefined
      ) as TopBarFilterItems<string> | undefined;

      return {
        ...acc,
        [fieldName]: fieldValue.map(({ value, selected }) =>
          Object.freeze({
            value,
            selected:
              fieldValueFromStorage?.find((item) => item?.value === value)
                ?.selected ?? selected,
          })
        ),
      };
    }, this.topBarTableFilter);

    this.$watch(
      () => {
        return this.topBarTableFilter;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return this.topBarTableHeaderValue;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    let showEmptyLoadingTimeoutId = 0;

    this.$watch(
      () => {
        return this.forceEmptyLoadingKey;
      },
      () => {
        this.cards.showEmptyLoading = true;
        window.clearTimeout(showEmptyLoadingTimeoutId);

        this.$nextTick(() => {
          showEmptyLoadingTimeoutId = window.setTimeout(() => {
            this.cards.showEmptyLoading = false;
          }, 500);
        });
      }
    );

    return this.$watch(
      () => {
        return [
          this.cards.itemsPerPage,
          this.forceUpdateUserWebPreferencesKey,
          this.cards.filter.owners.mediabuyer.length,
          this.cards.filter.owners.teamlead.length,
          this.cards.filter.owners.other.length,
        ].join("-");
      },
      debounce(() => {
        this.updateUserWebPreferencesAction({
          key: userWebPreferencesKey,
          value: {
            topBarTableFilter: this.topBarTableFilter,
            topBarTableHeaderValue: this.topBarTableHeaderValue,
            itemsPerPage: this.cards.itemsPerPage,
            filter: this.cards.filter,
          },
        });
      }, 500)
    );
  }

  protected createdHook(): void {
    this.$watch(
      () => {
        return this.items.length;
      },
      () => {
        if (!this.selectedCardsCount) return;

        this.selectedCards = {};
      }
    );

    this.topBarTableHeaderValue = this.topBarTableHeaderItems.map(
      ({ value, text }) =>
        Object.freeze({
          columnName: value,
          text,
          selected: true,
        })
    );
  }

  protected async mountedHook(): Promise<void> {
    if (!this.accessToLimitCardsPageGetter && !this.isPrepaidCardType) {
      this.$router.replace({ name: "cards" });
    } else {
      await this.fetchAvailablePromocodesAction();

      if (!this.loadedCardsGetter) {
        await this.fetchCards();
      }

      this.$watch(
        () => {
          return [
            this.loadedWalletsCurrenciesGetter,
            this.isReadyApp,
            this.loadedCardsGetter,
          ].join("-");
        },
        async () => {
          if (
            !this.loadedWalletsCurrenciesGetter ||
            !this.isReadyApp ||
            !this.loadedCardsGetter
          ) {
            return;
          }

          this.cards.loading = true;

          await Promise.all([
            this.fetchTeamMembersAction(),
            this.fetchAllBanksAction(),
          ]);

          this.topBarTableFilter.currency = this.walletsCurrenciesGetter.map(
            (value) =>
              Object.freeze({
                selected: true,
                value,
              })
          );

          this.mapTopBarTableFilterGroup();
          this.mapTopBarTableFilterBank();

          await this.initWebPreferences();

          this.cards.loading = false;
          this.cards.showEmptyLoading = false;

          this.$watch(
            () => {
              return this.cards.forceUpdateKey;
            },
            async () => {
              if (this.cards.showEmptyLoading) return;

              this.cards.showEmptyLoading = true;

              try {
                await this.fetchCards();
              } finally {
                this.cards.showEmptyLoading = false;
              }
            }
          );
        },
        {
          immediate: true,
        }
      );
    }
  }
}
